
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('externalIncentive.supplier_machinery_info') }} {{ $t('farm_config.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.type')"
                      label-for="type"
                      >
                      <b-form-select
                          plain
                          v-model="search.type"
                          :options="typeListD"
                          id="type"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('farm_config.machine_type')"
                      label-for="machine_type_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.machine_type_id"
                          :options="macMachineTypeList"
                          id="machine_type_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_config.instrument_name')"
                      label-for="instrument_name_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.instrument_name_id"
                          :options="instrumentList"
                          id="instrument_name_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('farm_config.brand')"
                      label-for="brand_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.brand_id"
                          :options="brandList"
                          id="brand_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('externalIncentive.chasis_no')"
                        label-for="chasis_no"
                        >
                        <b-form-input
                            plain
                            v-model="search.chasis_no"
                            id="chasis_no"
                            >
                        </b-form-input>
                    </b-form-group>
                </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('externalIncentive.supplier_machinery_info') }} {{ $t('farm_config.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('ministryDashboard.available')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('externalIncentive.distributed')}}</span>
                                    </template>
                                    <template v-slot:cell(machine_value)="data">
                                      {{ $n(data.item.machine_value, {  useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                      <i class="fas fa-eye"></i>
                                    </a>
                                    <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.save_status === 1" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                      <i class="ri-ball-pen-fill"></i>
                                    </a>
                                  </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('externalIncentive.supplier_machinery_info') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineryInfoList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        type: 0,
        machine_type_id: 0,
        instrument_name_id: 0,
        brand_id: 0,
        chasis_no: ''
      },
      rows: [],
      brandList: [],
      instrumentList: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
    macMachineTypeList: function () {
      return this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macMachineTypeList.filter(item => item.status === 1)
      .map(obj => {
        if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    typeListD: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'বিদেশী' : 'Foreign' }
      ]
      return list
    },
    typeList: function () {
      const list = [
        { value: 1, text: 'Local', text_en: 'Local', text_bn: 'স্থানীয়' },
        { value: 2, text: 'Foreign', text_en: 'Foreign', text_bn: 'বিদেশী' }
      ]
      return list
    },
    engineTypeList: function () {
      const list = [
        { value: 1, text: 'Digel', text_en: 'Digel', text_bn: 'ডিজেল' },
        { value: 2, text: 'Petrol', text_en: 'Petrol', text_bn: 'পেট্রোল' },
        { value: 3, text: 'Octane', text_en: 'Octane', text_bn: 'অকটেন' }
      ]
      return list
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('externalIncentive.supplier_machinery_info') + ' ' + this.$t('globalTrans.add') : this.$t('externalIncentive.supplier_machinery_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('farm_config.machine_type'), class: 'text-center' },
          { label: this.$t('dae_config.instrument_name'), class: 'text-center' },
          { label: this.$t('farm_config.brand'), class: 'text-center' },
          { label: this.$t('externalIncentive.engine_power'), class: 'text-center' },
          { label: this.$t('externalIncentive.chasis_no'), class: 'text-center' },
          { label: this.$t('externalIncentive.machine_value'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'machine_type_name_bn' },
          { key: 'machine_name_bn' },
          { key: 'brand_name_bn' },
          { key: 'engine_power' },
          { key: 'chasis_no' },
          { key: 'machine_value' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'machine_type_name' },
          { key: 'machine_name' },
          { key: 'brand_name' },
          { key: 'engine_power' },
          { key: 'chasis_no' },
          { key: 'machine_value' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.machine_type_id': function (newVal, oldVal) {
      this.instrumentList = this.getInstrumentList(newVal)
    },
    'search.instrument_name_id': function (newVal, oldVal) {
      this.brandList = this.getMacBrandList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, machineryInfoList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        if (item) {
          const machineTypeObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macMachineTypeList.find(data => data.value === parseInt(item.machine_type_id))
          const agMaterialTypeData = {
            machine_type_name: machineTypeObj !== undefined ? machineTypeObj.text_en : '',
            machine_type_name_bn: machineTypeObj !== undefined ? machineTypeObj.text_bn : ''
          }
          const instrumentObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.find(data => data.value === parseInt(item.instrument_name_id))
          const instrumentNameData = {
            machine_name: instrumentObj !== undefined ? instrumentObj.text_en : '',
            machine_name_bn: instrumentObj !== undefined ? instrumentObj.text_bn : ''
          }
          const brandObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macBrandList.find(data => data.value === parseInt(item.brand_id))
          const brandData = {
            brand_name: brandObj !== undefined ? brandObj.text_en : '',
            brand_name_bn: brandObj !== undefined ? brandObj.text_bn : ''
          }
          const CountryOriginObj = this.$store.state.ExternalUserIrrigation.commonObj.countryList.find(data => data.value === parseInt(item.county_origin_id))
          const countryOriginData = {
            countryOrigin: CountryOriginObj !== undefined ? CountryOriginObj.text_en : '',
            countryOrigin_bn: CountryOriginObj !== undefined ? CountryOriginObj.text_bn : ''
          }
          const countryManufacturerObj = this.$store.state.ExternalUserIrrigation.commonObj.countryList.find(data => data.value === parseInt(item.county_manufacturer_id))
          const countryManufacturerData = {
            manufacturer_name: countryManufacturerObj !== undefined ? countryManufacturerObj.text_en : '',
            manufacturer_name_bn: countryManufacturerObj !== undefined ? countryManufacturerObj.text_bn : ''
          }
          const typeObj = this.typeList.find(data => data.value === parseInt(item.type))
          const typeData = {
            type_en: typeObj !== undefined ? typeObj.text_en : '',
            type_bn: typeObj !== undefined ? typeObj.text_bn : ''
          }
          const engineTypeObj = this.engineTypeList.find(data => data.value === parseInt(item.engine_type))
          const engineTypeData = {
            engine_type_en: engineTypeObj !== undefined ? engineTypeObj.text_en : '',
            engine_type_bn: engineTypeObj !== undefined ? engineTypeObj.text_bn : ''
          }
          return Object.assign({}, item, agMaterialTypeData, instrumentNameData, brandData, countryOriginData, countryManufacturerData, typeData, engineTypeData)
        }
      })
      return listData
    },
    getInstrumentList (id = null) {
      const instrumentList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.filter(item => item.status === 1 && item.machine_type_id === id)
      return instrumentList.map(obj => {
        if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    getMacBrandList (id = null) {
      const brandList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macBrandList.filter(item => item.status === 1)
      if (id) {
        return brandList.filter(item => item.instrument_name_id === id)
      }
      return brandList
    }
  }
}
</script>
