<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                                        <hr class="mt-0">
                                          <b-row>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Origin Type" vid="type" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="type"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.origin_type')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.type"
                                                    :options="originTypeList"
                                                    id="type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Machine Type" vid="machine_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="machine_type_id"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.machine_type')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.machine_type_id"
                                                    :options="macMachineTypeList"
                                                    id="machine_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Machine Name" vid="instrument_name_id" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="instrument_name_id"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.machine_name')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.instrument_name_id"
                                                    :options="instrumentList"
                                                    id="instrument_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Brand Name" vid="brand_id" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="brand_id"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.brand')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.brand_id"
                                                    :options="brandList"
                                                    id="brand_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="County of Origin" vid="county_origin_id" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="county_origin_id"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.country_origin_name')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.county_origin_id"
                                                    :options="countryOriginList"
                                                    id="county_origin_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Manufacturer Country" vid="county_manufacturer_id" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="county_manufacturer_id"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.country_prepared_name')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.county_manufacturer_id"
                                                    :options="countryPreparedList"
                                                    id="county_manufacturer_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Engine Power" vid="engine_power" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="engine_power"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('externalIncentive.engine_power')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.engine_power"
                                                    :options="horsePowerListData"
                                                    id="engine_power"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Model" vid="model" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="model"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('externalIncentive.model')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.model"
                                                    :options="modelListData"
                                                    id="model"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Extend Model" vid="extend_model">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="extend_model"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.extended_model_no')}}
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.extend_model"
                                                    :options="extendedModelListData"
                                                    id="extend_model"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                              <ValidationProvider name="Plain Area" vid="plain_area" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="plain_area"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('farm_config.plain_area')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="plain_area"
                                                    v-model="machineryInfo.plain_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    disabled
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                              <ValidationProvider name="Coastal Area" vid="coastal_area" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="coastal_area"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('farm_config.coastal_area')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="coastal_area"
                                                    v-model="machineryInfo.coastal_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    disabled
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Engine No" vid="engine_no" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="engine_no"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('externalIncentive.engine_no')}}<span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="engine_no"
                                                    v-model="machineryInfo.engine_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Chasis No" vid="chasis_no" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="chasis_no"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                  {{ $t('externalIncentive.chasis_no')}}<span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="chasis_no"
                                                    v-model="machineryInfo.chasis_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Engine Type" vid="engine_type" rules="required|min_value:1">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="engine_type"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                  <template v-slot:label>
                                                    {{ $t('externalIncentive.engine_type')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                    plain
                                                    v-model="machineryInfo.engine_type"
                                                    :options="engineTypeList"
                                                    id="engine_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Machine Value" vid="machine_value" rules="required" >
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="machine_value"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('externalIncentive.machine_value')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="machine_value"
                                                    v-model="machineryInfo.machine_value"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Port Release Date" vid="port_release_date" rules="required" >
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="port_release_date"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('externalIncentive.port_release_date')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    class="date-picker"
                                                    v-model="machineryInfo.port_release_date"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="machineryInfo.type === 2">
                                              <ValidationProvider name="LC No" vid="lc_no" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="lc_no"
                                                  slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('externalIncentive.lc_no')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    id="lc_no"
                                                    v-model="machineryInfo.lc_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="machineryInfo.type === 2">
                                              <ValidationProvider name="Lc Attachment" vid="lc_attachment" :rules="id?'':'required'">
                                                <b-form-group class="row" label-cols-sm="4" label-for="lc_attachment" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('externalIncentive.lc_attachment') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-file
                                                    id="lc_attachment"
                                                    v-model="machineryInfo.lc_attachment"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    @change="onFileChange"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <div class="row">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                  <b-button type="submit" @click="machineryInfo.save_status = 1" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                                                  <b-button type="submit" @click="machineryInfo.save_status = 2" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                                                  <b-button variant="danger" class="mr-2 btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineryInfoStore, machineryInfoUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
      return {
          loading: false,
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          machineryInfo: {
            type: 0,
            machine_type_id: 0,
            instrument_name_id: 0,
            brand_id: 0,
            model: 0,
            extend_model: '',
            engine_no: '',
            chasis_no: '',
            county_origin_id: 0,
            county_manufacturer_id: 0,
            engine_power: 0,
            engine_type: 0,
            save_status: 1,
            port_release_date: '',
            machine_value: '',
            lc_no: '',
            lc_attachment: [],
            coastal_area: '',
            plain_area: ''
          },
          lc_attachmentFile: [],
          brandList: [],
          instrumentList: [],
          instrumentListAll: [],
          macMachineTypeList: [],
          countryOriginList: [],
          countryPreparedList: [],
          horsePowerList: [],
          modelList: [],
          modelListData: [],
          horsePowerListData: [],
          extendedModelListData: [],
          macMachineTypeLists: [],
          extendedModelList: [],
          originTypeList: [],
          showIdType: 0,
          ItemShow: false,
          user: ''
      }
    },
    created () {
      this.getSupplierAssignList()
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
       }),
      typeList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'বিদেশী' : 'Foreign' }
        ]
        return list
      },
      engineTypeList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'ডিজেল' : 'Digel' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'পেট্রোল' : 'Petrol' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'অকটেন' : 'Octane' }
        ]
        return list
      },
      machineTypeLists: function () {
        const list = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macMachineTypeList.filter(item => item.status === 1)
        return list.map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
            }
        })
      }
    },
    mounted () {
      flatpickr('.date-picker', {})
    },
    watch: {
        'machineryInfo.machine_type_id': function (newVal, oldVal) {
            this.instrumentList = this.getInstrumentList(newVal)
        },
        'machineryInfo.type': function (newVal, oldVal) {
            this.macMachineTypeList = this.getOriginWiseMachineType(newVal)
        },
        'machineryInfo.instrument_name_id': function (newVal, oldVal) {
            this.brandList = this.getMacBrandList(newVal)
        },
        'machineryInfo.brand_id': function (newVal, oldVal) {
            this.horsePowerListData = this.getHoursePowerList(newVal)
        },
        'machineryInfo.engine_power': function (newVal, oldVal) {
            this.modelListData = this.getModelList(newVal)
        },
        'machineryInfo.model': function (newVal, oldVal) {
          if (newVal) {
            this.extendedModelListData = this.getExtendedModelList(newVal)
           this.getPlainCoastalArea()
          }
        }
    },
    methods: {
      getSupplierData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      onFileChange (e) {
        this.lc_attachmentFile = e.target.files[0]
      },
      async saveUpdate () {
          this.loading = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
          var formData = new FormData()
          Object.keys(this.machineryInfo).map(key => {
            if (key === 'lc_attachment') {
              formData.append(key, this.lc_attachmentFile)
            } else {
              formData.append(key, this.machineryInfo[key])
            }
          })
          if (this.id) {
            formData.append('_method', 'POST')
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${machineryInfoUpdate}/${this.id}`, formData, config)
          } else {
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, machineryInfoStore, formData, config)
          }

          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          this.loading = false
          if (result.success) {
              this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
              this.$toast.success({
                  title: this.$t('globalTrans.success'),
                  message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                  color: '#D6E09B'
              })

              this.$bvModal.hide('modal-4')
          } else {
              this.$toast.error({
                title: 'Warning',
                message: result.message,
                color: '#D6E09B'
              })
              this.$refs.form.setErrors(result.errors)
          }
      },
      getOriginWiseMachineType (id = null) {
        const instrumentList = this.macMachineTypeLists.filter(item => item.origin_type === parseInt(id))
        return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getInstrumentList (id = null) {
        const instrumentList = this.instrumentListAll.filter(item => item.machine_type_id === parseInt(id))
        return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getModelList (horsePower = null) {
        const instrumentList = this.modelList.filter(item => String(item.horse_power) === String(horsePower))
        return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getHoursePowerList (brandId = null) {
        const list = this.horsePowerList.filter(item => parseInt(item.brand_id) === parseInt(brandId))
        return list.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getExtendedModelList (model = null) {
        const list = this.extendedModelList.filter(item => String(item.model) === String(model))
        return list.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getMacBrandList (id = null) {
        const brandList = this.brandListAll.filter(item => item.instrument_name_id === id)
        return brandList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
        })
      },
      async getSupplierAssignList () {
        this.loading = true
        const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/external/machinery-info/supplier-machinery-assign-list')
        if (result.success) {
          const data = result.data
          const allDatas = data.machineTypeList
          this.horsePowerList = data.horsePowerList
          this.modelList = data.modelList
          this.extendedModelList = data.extendedModelList
          if (allDatas.length > 0) {
            const mashTypeList = []
            const instrumentListAll = []
            const brandListAll = []
            const countryOriginList = []
            const countryPreparedList = []
            const originTypeList = []
            data.machineTypeList.forEach((item, key) => {
              const detail = this.machineTypeLists.find(items => items.value === parseInt(item.machine_type_id))
              detail.origin_type = item.origin_type
              mashTypeList.push(detail)
            })
            data.machineList.forEach((item, key) => {
              const instList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.find(items => items.value === parseInt(item))
              instrumentListAll.push(instList)
            })
            data.brandList.forEach((item, key) => {
              const brandListData = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macBrandList.find(items => items.value === parseInt(item))
              brandListAll.push(brandListData)
            })
            data.countryOrignList.forEach((item, key) => {
              const countryOriginData = this.$store.state.ExternalUserIrrigation.commonObj.countryList.find(items => items.value === parseInt(item))
              countryOriginList.push(countryOriginData)
            })
            data.countryPreparedList.forEach((item, key) => {
              const countryPreapredData = this.$store.state.ExternalUserIrrigation.commonObj.countryList.find(items => items.value === parseInt(item))
              countryPreparedList.push(countryPreapredData)
            })
            data.originTypeList.forEach((item, key) => {
              const originData = this.typeList.find(el => el.value === parseInt(item))
              originTypeList.push(originData)
            })
            this.macMachineTypeLists = mashTypeList
            this.instrumentListAll = instrumentListAll
            this.brandListAll = brandListAll
            this.countryOriginList = countryOriginList
            this.countryPreparedList = countryPreparedList
            this.originTypeList = originTypeList
            if (this.id) {
              const tmp = this.getSupplierData()
              this.machineryInfo = tmp
            }
          }
        }
        this.loading = false
      },
      async getPlainCoastalArea () {
        const searchParams = {
          origin_type: this.machineryInfo.type,
          machine_type_id: this.machineryInfo.machine_type_id,
          machine_id: this.machineryInfo.instrument_name_id,
          brand_id: this.machineryInfo.brand_id,
          country_origin_id: this.machineryInfo.county_origin_id,
          country_prepared_id: this.machineryInfo.county_manufacturer_id,
          model: this.machineryInfo.model
        }
        this.loading = true
        const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/external/machinery-info/get-plain-coastal-area', searchParams)
        if (result.success) {
          this.machineryInfo.plain_area = result.data.plain_area
          this.machineryInfo.coastal_area = result.data.coastal_area
        } else {
          this.machineryInfo.plain_area = ''
          this.machineryInfo.coastal_area = ''
        }
        this.loading = false
      }
    }
}
</script>
