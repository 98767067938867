<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple bordered>
                            <b-tr>
                              <b-th style="width:25%">{{ $t('globalTrans.type') }}</b-th>
                              <b-td style="width:25%">{{ ($i18n.locale=='bn') ? detailsData.type_bn : detailsData.type_en }}</b-td>
                              <b-th style="width:25%">{{ $t('farm_config.machine_type') }}</b-th>
                              <b-td style="width:25%">{{ ($i18n.locale=='bn') ? detailsData.machine_type_name_bn : detailsData.machine_type_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('dae_config.instrument_name') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? detailsData.machine_name_bn : detailsData.machine_name }}</b-td>
                              <b-th>{{ $t('farm_config.brand') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? detailsData.brand_name_bn : detailsData.brand_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalIncentive.model') }}</b-th>
                              <b-td>{{ detailsData.model }}</b-td>
                              <b-th>{{ $t('farm_config.extended_model_no') }}</b-th>
                              <b-td>{{ detailsData.extend_model }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalIncentive.engine_no') }}</b-th>
                              <b-td>{{ detailsData.engine_no }}</b-td>
                              <b-th>{{ $t('externalIncentive.chasis_no') }}</b-th>
                              <b-td>{{ detailsData.chasis_no }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('germplasm.country_of_origin') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? detailsData.countryOrigin_bn : detailsData.countryOrigin }}</b-td>
                              <b-th>{{ $t('externalIncentive.manufacturer_origin') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? detailsData.manufacturer_name_bn : detailsData.manufacturer_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalIncentive.engine_power') }}</b-th>
                              <b-td>{{ detailsData.engine_power }}</b-td>
                              <b-th>{{ $t('externalIncentive.engine_type') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? detailsData.engine_type_bn : detailsData.engine_type_en }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalIncentive.machine_value') }}</b-th>
                              <b-td>{{ $n(detailsData.machine_value, { minimumFractionDigits: 2 }) }}</b-td>
                              <b-th>{{ $t('externalIncentive.port_release_date') }}</b-th>
                              <b-td>{{ detailsData.port_release_date|dateFormat }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th v-if="detailsData.lc_no">{{ $t('externalIncentive.lc_no') }}</b-th>
                              <b-td v-if="detailsData.lc_no">{{ detailsData.lc_no }}</b-td>
                              <b-th v-if="detailsData.lc_attachment">{{ $t('externalIncentive.lc_attachment') }}</b-th>
                              <b-td v-if="detailsData.lc_attachment">
                                <a :href="incentiveGrantServiceBaseUrl + 'download-attachment?file=uploads/farmMachine/lc-attachment/original/' + detailsData.lc_attachment" target="_blank">
                                  {{ $t('research_seed.download') }}
                                  <i class="ri-download-cloud-line" ></i>
                                </a>
                              </b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.detailsData = this.item
  },
  data () {
    return {
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      detailsData: {},
      slOffset: 1
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    }
  },
  methods: {
    getAreaType (status) {
      if (status === 1) {
        return this.$i18n.locale === 'en' ? 'City Corpoation' : 'সিটি কর্পোরেশন'
      } else if (status === 2) {
        return this.$i18n.locale === 'en' ? 'Pauroshoba' : 'পৌরসভা'
      } else if (status === 3) {
        return this.$i18n.locale === 'en' ? 'Union' : 'ইউনিয়ন'
      }
    },
    getDivisionName (divisionId) {
      const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(divisionId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getDistrictName (districtId) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUnionName (upazilaId) {
      const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getCityCorporationName (upazilaId) {
      const obj = this.$store.state.commonObj.cityCorporationList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
