
export const profileInfoBaseUrl = '/farmMachinery/config/supplier-info/'
export const profileInfoShow = profileInfoBaseUrl + 'profile'

// Supplier Info Routes...
const machineryInfo = '/farmMachinery/external/machinery-info/'
export const machineryInfoStore = machineryInfo + 'store'
export const machineryInfoList = machineryInfo + 'list'
export const machineryInfoToggleStatus = machineryInfo + 'toggle-status'
export const machineryInfoUpdate = machineryInfo + 'update'
